@font-face {
  font-family: "Ubuntu";
  src: url("../fonts/Ubuntu-R.ttf");
}
body {
  font-family: "Ubuntu";
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  color: #f1f1f1;
  text-align: center;
  font-size: 165%;
  background-color: #070707;
  overflow: hidden;
  cursor: default;
}

::-moz-selection {
  background-color: rgba(205, 139, 255, 0.3137254902);
  color: #f1f1f1;
}

::selection {
  background-color: rgba(205, 139, 255, 0.3137254902);
  color: #f1f1f1;
}

* {
  color: #f1f1f1;
  outline: none;
}

#content {
  opacity: 0;
  width: 750px;
  left: calc(50% - 375px);
}

#mobileText {
  opacity: 1;
  font-weight: bold;
  text-align: center;
  width: 90%;
  left: 5%;
  font-size: calc(2.5vw + 2.5vh);
}

#content, #mobileText {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

@media only screen and (min-width: 850px) and (min-height: 600px) {
  #content {
    opacity: 1;
  }
}
@media only screen and (min-width: 850px) and (min-height: 600px) {
  #mobileText {
    opacity: 0;
  }
}
#welcomeText {
  opacity: 50%;
  font-weight: normal;
  font-size: 120%;
  margin-bottom: 5px;
}

h1 {
  font-size: 270%;
  margin-bottom: 7px;
}

h1, #mobileText {
  margin: 0;
  color: #CC8BFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

h2 {
  margin-bottom: 12px;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.small {
  display: inline-block;
  margin-left: 5px;
  color: #d3d3d3;
  font-size: 50%;
  font-weight: bold;
  text-decoration: none;
}

#contentBacking {
  position: absolute;
  top: 150px;
  left: 0;
  right: 0;
  bottom: 25px;
  filter: blur(15px);
  background-color: rgba(10, 10, 10, 0.5);
  z-index: -1;
}

.h3Div {
  margin: 16px 35px 16px 35px;
  display: flex;
  text-align: left;
  font-size: 110%;
}
.h3Div h3 {
  font-weight: normal;
  width: 21%;
  font-size: 100%;
}
.h3Div ul {
  margin: 0;
  width: 70%;
  padding-inline-start: 0;
  list-style-type: none;
  font-weight: bold;
  letter-spacing: 0.04em;
}
.h3Div .hash {
  line-height: 1.4;
  width: 4%;
  margin-top: -2px;
}
.h3Div .elementLine {
  letter-spacing: 0.06em;
}
.h3Div h3, .h3Div .elementLine, .h3Div .underline {
  margin: 2px 0 2px 0;
}
.h3Div a {
  text-decoration: none;
  margin-right: 16px !important;
}
.h3Div a:has(i) {
  text-decoration: none;
}
.h3Div a i {
  font-family: "FontAwesome";
  margin-right: 8px;
  text-decoration: none !important;
  font-weight: normal;
  font-style: normal;
  height: 25px;
  width: 25px;
}

.underline, a:has(i), .backBtn {
  display: inline-block;
}

.underline {
  -webkit-text-decoration: underline solid 3px rgba(205, 139, 255, 0.3137254902);
          text-decoration: underline solid 3px rgba(205, 139, 255, 0.3137254902);
  transition: -webkit-text-decoration 0.25s;
  transition: text-decoration 0.25s;
  transition: text-decoration 0.25s, -webkit-text-decoration 0.25s;
}

.underline:hover,
.underline:focus,
a:hover > * > .underline,
a:focus > * > .underline {
  color: white;
  -webkit-text-decoration: underline solid 3px #CC8BFF;
          text-decoration: underline solid 3px #CC8BFF;
  transition: -webkit-text-decoration 0.25s;
  transition: text-decoration 0.25s;
  transition: text-decoration 0.25s, -webkit-text-decoration 0.25s;
}

.accent {
  color: #CC8BFF;
}

.tooltip {
  transition: 200ms color;
}
.tooltip span {
  transition: 200ms opacity;
}

.tooltip::before,
.tooltip::after {
  --opacity: 0;
  position: absolute;
  opacity: var(--opacity);
  transition: 200ms opacity;
}

.tooltip::before {
  content: attr(tooltip);
  color: #CC8BFF;
  font-size: 75%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.0823529412) 0%, transparent 100%);
  border-radius: 4px;
  padding: 0.2em;
}

.tooltip:hover {
  color: transparent;
  transition: 200ms color;
}
.tooltip:hover::before {
  --opacity: 1;
}
.tooltip:hover span {
  opacity: 0;
  transition: 100ms opacity;
}

hr {
  height: 12px;
  width: 100%;
  margin: 0;
  margin-top: 23px;
  border: 3px solid;
  border-top: none;
  opacity: 75%;
  transform: translateY(-12px);
}

.rotate180 {
  transform: translateY(14px) rotateX(180deg);
  margin-top: 0;
  margin-bottom: 23px;
}

.backBtn {
  position: absolute;
  left: 0;
  transform: translateX(85px) translateY(8px);
  text-decoration: none;
  transition: 150ms color;
}
.backBtn:hover, .backBtn:focus {
  color: #CC8BFF;
  transition: 150ms color;
}

#background {
  height: 100%;
  width: 100%;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  pointer-events: none;
  position: absolute;
  opacity: 15% !important;
  filter: blur(17.5px) contrast(0.9);
}

.webPreview {
  height: 167px;
  width: 552px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  background-color: #222222;
  border: solid 1px #363636;
  text-align: left;
}
.webPreview ul {
  vertical-align: top;
  padding-inline-start: 0;
  display: inline-block;
}
.webPreview ul a {
  font-weight: bold;
  margin-bottom: 5px;
  width: 360px;
  font-size: 110%;
}
.webPreview ul p {
  color: #d3d3d3;
  margin-top: 5px;
  width: 360px;
  font-size: 60%;
}
.webPreview ul p b {
  color: #dfdfdf;
}
.webPreview img {
  height: calc(100% - 36px);
  width: auto;
  margin: 18px;
}/*# sourceMappingURL=godden.css.map */